/* global Bloodhound app */
(function($){

  var charMap = {'àâ': 'a', 'èéêë': 'e', 'ç': 'c', 'îï': 'i', 'ù': 'u', 'ô': 'o'};

  function replaceAccents(str){
    $.each(charMap, function(pattern, replace) {
      str = str.replace(new RegExp("[" + pattern + "]", "gi"), replace);
    });
    return str;
  }

  function suggestionTemplate(data) {
    var str = '<div class="media"';
    if (data.ga) {
      str += "data-ga='"+ data.ga + "' ga-track='productClick'";
    }
    str += '>';
    str += '<div class="media-left">';
    if (data.image) {
      str += '<img src="'+data.image+'">';
    }
    str += '</div>';
    str += '<div class="media-body">';
    str += '<div class="tt-title">'+data.name+'</div>';
    if (data.extra) {
      str += '<div class="tt-suggestion-extra">'+data.extra+'</div>';
    }
    str += '</div>';
    str += '</div>';
    return str;
  }

  $(function(){

    var $searchInputs = $('.search-field-input');

    $searchInputs.each(function(){
      var searchInput = $(this);

      searchInput.on('focus', function(){
        $(this).prev().addClass('focus');
      });
      searchInput.on('blur', function(){
        $(this).prev().removeClass('focus');
      });
      searchInput.on('mouseover', function(){
        $(this).prev().addClass('hover');
      });
      searchInput.on('mouseout', function(){
        $(this).prev().removeClass('hover');
      });

      searchInput.one('focus', function(){
        searchInput.on('typeahead:close', function(){
          $(this).prev().removeClass('open');
        });

        var source = new Bloodhound({
          prefetch: app.typeaheadPrefetchURL,
          identify: function(obj) { return obj.id; },
          sorter: function(a, b) {
            return b.popularity - a.popularity;
          },
          matchAnyQueryToken: true,
          queryTokenizer: function(str) {
            return Bloodhound.tokenizers.whitespace(str);
          },
          datumTokenizer: function(datum) {
            var name;
            var extra;
            var tokens = [];
            if (datum.name) {
              name = replaceAccents(datum.name);
            } else {
              name = '';
            }

            extra = '';
            if (! datum.extra) {
              datum.extra = [];
            }
            if (datum.extra && !Array.isArray(datum.extra)) {
              datum.extra = [datum.extra];
            }
            if (datum.extra) {
              datum.extra.forEach(function(str){
                extra += str.replace('de ', '').replace('from ', '');
              });
            }
            tokens = tokens.concat(Bloodhound.tokenizers.nonword(name));
            tokens = tokens.concat(Bloodhound.tokenizers.nonword(extra));
            tokens = tokens.concat(Bloodhound.tokenizers.whitespace(datum.search_only));
            return tokens;
          },
        });

        searchInput.typeahead({
          highlight: false,
          minLength: 2,
          autoselect: false,
          hint: true,
        }, {
          name: 'default',
          display: 'name',
          limit: 7,
          source: source,
          templates: {
            suggestion: suggestionTemplate,
          }
        });

        function toggleOpen() {
          if ($(this).parent().find('.tt-open .tt-suggestion').length) {
            $(this).prev().addClass('open');
          } else {
            $(this).prev().removeClass('open');
          }
        }
        searchInput.on('keyup', toggleOpen);
        searchInput.on('typeahead:open', toggleOpen);

        searchInput.on('typeahead:render', app.utils.debounce(function(e, datums){
          var items = [];

          datums.forEach(function(datum, index){
            if (datum.ga) {
              var data = JSON.parse(datum.ga);
              data.index = index;
              items.push(data);
            }
          });

          if (items.length && window.gtag) {
            window.gtag('event', 'view_item_list', {
              'item_list_name': 'Recherche: Suggestions',
              'items': items
            });
          }
        }, 1000));

        searchInput.on('typeahead:selected', function(event, datum){
          if (datum.url) {
            window.location = datum.url;
            // prevent form submission when pressing enter twice
            $(this).closest('form').one('submit', function(){return false;});
          } else {
            $(this).closest('form').submit();
          }
        });

        searchInput.focus();

        function fixTypeaheadShouldSubmitOnEnterKeyWhenMouseOverMenu(){
          var typeahead = searchInput.data('ttTypeahead');
          var shouldTriggerSearch = true;

          searchInput.on('typeahead:cursorchange', function(e, suggestion){
            if (suggestion) {
              shouldTriggerSearch = false;
            } else {
              shouldTriggerSearch = true;
            }
          });

          typeahead.menu.$node.on('click', typeahead.selectors.selectable, function(e){
            shouldTriggerSearch = false;
            typeahead.select($(e.currentTarget));
          });

          searchInput.on('typeahead:beforeselect', function(e){
            if (shouldTriggerSearch) {
              e.preventDefault();
            }
          });
        }
        fixTypeaheadShouldSubmitOnEnterKeyWhenMouseOverMenu();

      });
    });

  });
}(jQuery));

